import { useState, useCallback } from "react";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const sendRequest = useCallback(async (request) => {
    setIsLoading(true);

    try {
      const response = await request;

      if (response.statusText !== "OK") {
        throw new Error(response.data.message);
      }

      setIsLoading(false);

      return response.data;
    } catch (err) {
      setError({
        message: err.response.data.message,
        ...(err.response?.data?.param
          ? { param: err.response.data.param }
          : {}),
      });
      setIsLoading(false);
      throw err;
    }
  }, []);

  const clearError = () => {
    setError(null);
  };

  const paramError = (param) =>
    error?.param && error.param === param ? error.message : "";

  return [sendRequest, isLoading, error, paramError, clearError];
};
