// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./programSessionStyle";
import strings from "constants/strings";
import SelectInput from "components/SelectInput/SelectInput";

const useStyles = makeStyles(styles);

export default function ProgramSessionAssign(props) {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      startDate: "",
      endDate: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getProgramSession(id));
          setValue("startDate", response.startDate);
          setValue("endDate", response.endDate);
          setProgramSessionId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [programSessionId, setProgramSessionId] = React.useState();
  const [image, setImage] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateProgramSession = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;

    try {
      const response = await sendRequest(
        api.updateProgramSession({
          ...(programSessionId ? {id: programSessionId} : {}),
          startDate: getValues("startDate"),
          endDate: getValues("endDate"),
        }),
      );

      if (!programSessionId) {
        setProgramSessionId(response.id);
        history.push({
          pathname: `/admin/programs/${props.program.id}/programsessions/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateProgramSession)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("startDate"),
                            value: props.value,
                            type: "date",
                          }}
                          labelText="Start Date"
                          error={!!errors.startDate?.message}
                          helperText={errors.startDate?.message}
                        />
                      )}
                      name="startDate"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("endDate"),
                            value: props.value,
                            type: "date",
                          }}
                          labelText="End Date"
                          error={!!errors.endDate?.message}
                          helperText={errors.endDate?.message}
                        />
                      )}
                      name="endDate"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                </GridContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}>
                    {`${programSessionId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
