exports.ProgramCategories = {
  LANGUAGE_TRAIN: 0,
  CHARACTER_BUILDING: 1,
  SKILLS_DEVELOPEMENT: 2,
};

exports.ProgramType = {
  LIVE: 0,
  RECORDED: 1,
  BOTH: 2,
};

exports.ProgramStatus = {
  OPEN: 0,
  UPCOMING: 1,
  CLOSED: 2,
};

exports.PaymentStatus = {
  PENDING: 0,
  PAID: 1,
  CANCELLED: 2,
};

exports.HasCertificate = { NO: 0, YES: 1 };

exports.PaymentType = { BANK_TRANSFER: 0, ONLINE: 1 };

exports.PromoStatus = { NOTAPPLIED: 0, APPLIED: 1, USED: 2 };
