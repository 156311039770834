// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import React from "react";
import { useHistory } from "react-router";
import strings from "constants/strings";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./programSessionListStyle";
import { Controller, useForm } from "react-hook-form";
import CustomModal from "components/CustomModal/CustomModal";
import SelectInput from "components/SelectInput/SelectInput";

const useStyles = makeStyles(styles);

export default function ProgramSessionList(props) {
  const classes = useStyles();

  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      startDate: "",
      endDate: "",
      timeZoneEn: "",
      timeZoneAr: "",
      weekDays: {},
    },
  });

  React.useEffect(() => {
    (async () => {
      try {
        const data = await sendRequest(
          api.getProgramSessions(props.program.id)
        );
        console.log(data);
        setProgramSessions(data);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const handleChange = (prop) => (event) => {
    setValue(prop, event.target.value);
  };

  const handleHasCertificateChange = (orderId) => (event) => {
    (async () => {
      try {
        await api.changeOrderHasCertificate({
          id: orderId,
          hasCertificate: event.target.value,
        });
        let students = enrolledStudents.map((student) => {
          if (student.orderId === orderId) {
            student.hasCertificate = event.target.value;
          }
          return student;
        });
        setEnrolledStudents(students);
      } catch (err) {
      } finally {
      }
    })();
  };

  const addDay = (day) => {
    let weekDaysObject = { ...weekDays };
    if (day in weekDays) {
      delete weekDaysObject[day];
    } else {
      weekDaysObject = {
        ...weekDays,
        [day]: { from: "", to: "" },
      };
    }
    setWeekDays(weekDaysObject);
  };

  const handleTimeChange = (fieldName, day, event) => {
    let weekDaysObject = { ...weekDays };
    weekDaysObject[day][fieldName] = event.target.value;
    setWeekDays(weekDaysObject);
  };

  const [pageLoading, setPageLoading] = React.useState(true);

  const [programSessions, setProgramSessions] = React.useState([]);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [current, setCurrent] = React.useState();

  const [currentProgramSession, setCurrentProgramSession] = React.useState();
  const [enrolledStudents, setEnrolledStudents] = React.useState([]);

  const [weekDays, setWeekDays] = React.useState({});

  const [showProgramSessionModal, setShowProgramSessionModal] = React.useState(
    false
  );
  const [sendRequest, isLoading] = useHttpClient();

  const openProgramSessionModal = (index) => () => {
    setShowProgramSessionModal(true);
    setCurrentProgramSession(index);
    setValue("startDate", programSessions[index]?.startDate || "");
    setValue("endDate", programSessions[index]?.endDate || "");
    setValue("timeZoneEn", programSessions[index]?.timeZone?.en || "");
    setValue("timeZoneAr", programSessions[index]?.timeZone?.ar || "");
    if (programSessions[index]?.weekDays) {
      setWeekDays(programSessions[index].weekDays);
    }
    if (index < programSessions.length) {
      setEnrolledStudents(programSessions[index].students);
    }
  };

  const closeProgramSessionModal = () => {
    setShowProgramSessionModal(false);
    setCurrentProgramSession(undefined);
    setValue("startDate", "");
    setValue("endDate", "");
    setValue("timeZoneEn", "");
    setValue("timeZoneAr", "");
    clearErrors("startDate");
    clearErrors("endDate");
    clearErrors("timeZoneEn");
    clearErrors("timeZoneAr");
    setWeekDays({});
    setEnrolledStudents([]);
  };

  const openDeleteModal = (index) => () => {
    setShowDeleteModal(true);
    setCurrent(index);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDeleteProgramSession = async () => {
    try {
      await sendRequest(api.deleteProgramSession(programSessions[current].id));

      setProgramSessions((prevProgramSessions) => {
        let newProgramSessions = [...prevProgramSessions];
        newProgramSessions.splice(current, 1);
        return newProgramSessions;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const handleAddProgramSession = () => {
    const newIndex = programSessions.length;
    openProgramSessionModal(newIndex)();
  };

  const handleSaveProgramSession = async () => {
    try {
      const response = await sendRequest(
        api.updateProgramSession({
          ...programSessions[currentProgramSession],
          programId: props.program.id,
          startDate: getValues("startDate"),
          endDate: getValues("endDate"),
          timeZone: {
            en: getValues("timeZoneEn"),
            ar: getValues("timeZoneAr"),
          },
          weekDays,
        })
      );
      setProgramSessions((prevProgramSessions) => {
        let newProgramSessions = [...prevProgramSessions];
        newProgramSessions[currentProgramSession] = response;
        return newProgramSessions;
      });
    } catch (err) {
    } finally {
      closeProgramSessionModal();
    }
  };

  let daysArray = [];
  // {strings.daysOfTheWeek.map((day, index) => (
  Object.keys(strings.daysOfTheWeek).forEach((day, index) => {
    daysArray.push([
      <Button
        onClick={() => addDay(index)}
        key={index}
        className={index in weekDays ? classes.buttonSelected : classes.button}
        round
        color={index in weekDays ? "primary" : "white"}
      >
        {strings.daysOfTheWeek[index]}
      </Button>,
    ]);
  });

  let fromTo = [];
  Object.keys(weekDays).forEach((day, index) => {
    fromTo.push([
      <div key={index}>
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={2}
            className={classes.centerDay + " " + classes.paddingFromTo}
          >
            {strings.daysOfTheWeek[day]}
          </GridItem>
          <GridItem xs={12} sm={12} md={2} className={classes.paddingFromTo}>
            <label className={classes.contentLabel}>From</label>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: 0 },
              }}
              inputProps={{
                onChange: (event) => handleTimeChange("from", day, event),
                value: weekDays[day].from,
                type: "time",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2} className={classes.paddingFromTo}>
            <label className={classes.contentLabel}>To</label>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: 0 },
              }}
              inputProps={{
                onChange: (event) => handleTimeChange("to", day, event),
                value: weekDays[day].to,
                type: "time",
              }}
            />
          </GridItem>
        </GridContainer>
      </div>,
    ]);
  });

  const tableData = programSessions.map((member, index) => [
    member.startDate,
    member.endDate,
    <div>
      <EditIcon
        className={classes.editIcon}
        onClick={openProgramSessionModal(index)}
      />
      <DeleteIcon
        className={classes.deleteIcon}
        fontSize="small"
        onClick={openDeleteModal(index)}
      />
    </div>,
  ]);

  const tableEnrolledStudents = enrolledStudents.map((member, index) => [
    member.name,
    member.email,
    member.countryCode + member.phone,
    <SelectInput
      labelText=""
      // placeholder=""
      data={Object.keys(strings.hasCertificate).map((key, index) => ({
        name: strings.hasCertificate[key],
        value: key,
      }))}
      value={member.hasCertificate}
      onSelect={handleHasCertificateChange(member.orderId)}
    />,
  ]);

  return (
    <div>
      <div className={classes.topContainer}>
        <Button color="primary" round={true} onClick={handleAddProgramSession}>
          Create
        </Button>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Start Date", "End Date", "Actions"]}
                tableData={tableData}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <CustomModal
        open={showProgramSessionModal}
        onClose={closeProgramSessionModal}
      >
        <form onSubmit={handleSubmit(handleSaveProgramSession)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label className={classes.contentLabel}>Start Date</label>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: 0 },
                    }}
                    inputProps={{
                      onChange: handleChange("startDate"),
                      value: props.value,
                      type: "date",
                    }}
                    error={!!errors.startDate?.message}
                    helperText={errors.startDate?.message}
                  />
                )}
                name="startDate"
                control={control}
                rules={{ required: "Required" }}
              />
              <label className={classes.contentLabel}>
                Time Zone (English)
              </label>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: 0 },
                    }}
                    inputProps={{
                      onChange: handleChange("timeZoneEn"),
                      value: props.value,
                    }}
                    error={!!errors.timeZoneEn?.message}
                    helperText={errors.timeZoneEn?.message}
                  />
                )}
                name="timeZoneEn"
                control={control}
                // rules={{required: "Required"}}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <label className={classes.contentLabel}>End Date</label>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: 0 },
                    }}
                    inputProps={{
                      onChange: handleChange("endDate"),
                      value: props.value,
                      type: "date",
                    }}
                    error={!!errors.endDate?.message}
                    helperText={errors.endDate?.message}
                  />
                )}
                name="endDate"
                control={control}
                rules={{ required: "Required" }}
              />
              <label className={classes.contentLabel}>Time Zone (Arabic)</label>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: 0 },
                    }}
                    inputProps={{
                      onChange: handleChange("timeZoneAr"),
                      value: props.value,
                    }}
                    error={!!errors.timeZoneAr?.message}
                    helperText={errors.timeZoneAr?.message}
                  />
                )}
                name="timeZoneAr"
                control={control}
                // rules={{required: "Required"}}
              />
            </GridItem>
          </GridContainer>
          {daysArray}
          <div style={{ marginTop: 30 }}>{fromTo}</div>
          <div className={classes.modalButtonContainer}>
            <Button
              color="danger"
              round={true}
              loadingColor="white"
              onClick={closeProgramSessionModal}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              round={true}
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </form>
        {programSessions[currentProgramSession]?.id ? (
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardBody>
                  <label className={classes.contentLabel}>
                    Total Enrolled Students: {enrolledStudents.length}
                  </label>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Name", "Email", "Phone", "Certificate"]}
                    tableData={tableEnrolledStudents}
                    pagination
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
      </CustomModal>
      <ConfirmModal
        isOpen={showDeleteModal}
        onClose={() => {
          closeDeleteModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Program Session"
        confirmMessage={"Are you sure you want to delete this session?"}
        errorMessage="Something went wrong."
        successMessage="Program Session deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteProgramSession}
        loading={isLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
