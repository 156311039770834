// @material-ui/icons
import TestimonialsIcon from "@material-ui/icons/Chat";
import NewsletterIcon from "@material-ui/icons/Email";
import TeamIcon from "@material-ui/icons/Group";
import AdminsIcon from "@material-ui/icons/Lock";
import PartnersIcon from "@material-ui/icons/WorkOutlined";
import MetaIcon from "@material-ui/icons/Build";
import StudentsIcon from "@material-ui/icons/School";
import InstructorIcon from "@material-ui/icons/Person";
import ProgramIcon from "@material-ui/icons/EventNote";
import OrdersIcon from "@material-ui/icons/Receipt";
import PromosIcon from "@material-ui/icons/LocalOffer";
import AdminAssign from "views/Admins/pages/AdminAssign/AdminAssign";
import AdminList from "views/Admins/pages/AdminList/AdminList";
import Newsletter from "views/Newsletter/Newsletter";
import LoginPage from "views/Pages/LoginPage.js";
import PartnerAssign from "views/Partners/pages/PartnerAssign/PartnerAssign";
import PartnerList from "views/Partners/pages/PartnerList/PartnerList";
import TeamAssign from "views/Team/pages/TeamAssign/TeamAssign";
import TeamList from "views/Team/pages/TeamList/TeamList";
import Testimonials from "views/Testimonials/Testimonials";
import InstructorAssign from "views/Instructors/pages/InstructorAssign/InstructorAssign";
import InstructorList from "views/Instructors/pages/InstructorList/InstructorList";
import StudentAssign from "views/Students/pages/StudentAssign/StudentAssign";
import StudentList from "views/Students/pages/StudentList/StudentList";
import OrderAssign from "views/Orders/pages/OrderAssign/OrderAssign";
import OrderList from "views/Orders/pages/OrderList/OrderList";
import ProgramAssign from "views/Programs/pages/ProgramAssign/ProgramAssign";
import ProgramList from "views/Programs/pages/ProgramList/ProgramList";
import ProgramSessionAssign from "views/Programs/components/ProgramSessions/pages/ProgramSessionAssign/ProgramSessionAssign";
import Meta from "views/Meta/Meta";
import PromoAssign from "views/Promos/pages/PromoAssign/PromoAssign";
import PromoList from "views/Promos/pages/PromoList/PromoList";
import Dashboard from "views/Dashboard/Dashboard";

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: InstructorIcon,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/programs",
    name: "Programs",
    icon: ProgramIcon,
    component: ProgramList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/programs/create",
        name: "Create Program",
        component: ProgramAssign,
        layout: "/admin",
      },
      {
        path: "/programs/edit/:id",
        name: "Edit Program",
        component: ProgramAssign,
        layout: "/admin",
      },
      {
        path: "/programs/:id/programsessions/create",
        name: "Create Program Session",
        component: ProgramSessionAssign,
        layout: "/admin",
      },
      {
        path: "/programs/:id/programsessions/edit/:id",
        name: "Edit Program Session",
        component: ProgramSessionAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/instructors",
    name: "Instructors",
    icon: InstructorIcon,
    component: InstructorList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/instructors/create",
        name: "Create Instructor",
        component: InstructorAssign,
        layout: "/admin",
      },
      {
        path: "/instructors/edit/:id",
        name: "Edit Instructor",
        component: InstructorAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/students",
    name: "Students",
    icon: StudentsIcon,
    component: StudentList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/students/create",
        name: "Create Student",
        component: StudentAssign,
        layout: "/admin",
      },
      {
        path: "/students/edit/:id",
        name: "Edit Student",
        component: StudentAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/orders",
    name: "Orders",
    icon: OrdersIcon,
    component: OrderList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/orders/create",
        name: "Create Order",
        component: OrderAssign,
        layout: "/admin",
      },
      {
        path: "/orders/edit/:id",
        name: "Edit Order",
        component: OrderAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/promos",
    name: "Promos",
    icon: PromosIcon,
    component: PromoList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/promos/create",
        name: "Create Promo",
        component: PromoAssign,
        layout: "/admin",
      },
      {
        path: "/promos/edit/:id",
        name: "Edit Promo",
        component: PromoAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/team",
    name: "Team",
    icon: TeamIcon,
    component: TeamList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/team/create",
        name: "Create Team Member",
        component: TeamAssign,
        layout: "/admin",
      },
      {
        path: "/team/edit/:id",
        name: "Edit Team Member",
        component: TeamAssign,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/partners",
    name: "Partners",
    icon: PartnersIcon,
    component: PartnerList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/partners/create",
        name: "Create Partner",
        component: PartnerAssign,
        layout: "/admin",
      },
      {
        path: "/partners/edit/:id",
        name: "Edit Partner",
        component: PartnerAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: NewsletterIcon,
    component: Newsletter,
    layout: "/admin",
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    icon: TestimonialsIcon,
    component: Testimonials,
    layout: "/admin",
  },
  {
    path: "/meta",
    name: "Meta Data",
    icon: MetaIcon,
    component: Meta,
    layout: "/admin",
  },
  {
    path: "/admins",
    name: "Admins",
    icon: AdminsIcon,
    component: AdminList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/admins/create",
        name: "Create Admin",
        component: AdminAssign,
        layout: "/admin",
      },
      {
        path: "/admins/edit/:id",
        name: "Edit Admin",
        component: AdminAssign,
        layout: "/admin",
      },
    ],
  },
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
];
export default dashRoutes;
