// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./teamAssignStyle";

const useStyles = makeStyles(styles);

export default function TeamAssign() {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      nameEn: "",
      nameAr: "",
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getTeamMember(id));
          setValue("nameEn", response.name.en);
          setValue("nameAr", response.name.ar);
          setValue("titleEn", response.title.en);
          setValue("titleAr", response.title.ar);
          setValue("descriptionEn", response.description.en);
          setValue("descriptionAr", response.description.ar);
          setValue("facebook", response.facebook);
          setValue("twitter", response.twitter);
          setValue("linkedin", response.linkedin);
          setTeamMemberId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [teamMemberId, setTeamMemberId] = React.useState();
  const [image, setImage] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateTeamMember = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;

    try {
      const response = await sendRequest(
        api.updateTeamMember({
          ...(teamMemberId ? { id: teamMemberId } : {}),
          name: { en: getValues("nameEn"), ar: getValues("nameAr") },
          title: { en: getValues("titleEn"), ar: getValues("titleAr") },
          description: {
            en: getValues("descriptionEn"),
            ar: getValues("descriptionAr"),
          },
          facebook: getValues("facebook"),
          twitter: getValues("twitter"),
          linkedin: getValues("linkedin"),
        })
      );

      if (!teamMemberId) {
        setTeamMemberId(response.id);
        history.push({
          pathname: `/admin/team/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateTeamMember)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("titleEn"),
                            value: props.value,
                          }}
                          labelText="Title (English)"
                          error={!!errors.titleEn?.message}
                          helperText={errors.titleEn?.message}
                        />
                      )}
                      name="titleEn"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("titleAr"),
                            value: props.value,
                          }}
                          labelText="Title (Arabic)"
                          error={!!errors.titleAr?.message}
                          helperText={errors.titleAr?.message}
                        />
                      )}
                      name="titleAr"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("nameEn"),
                            value: props.value,
                          }}
                          labelText="Name (English)"
                          error={!!errors.nameEn?.message}
                          helperText={errors.nameEn?.message}
                        />
                      )}
                      name="nameEn"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("nameAr"),
                            value: props.value,
                          }}
                          labelText="Name (Arabic)"
                          error={!!errors.nameAr?.message}
                          helperText={errors.nameAr?.message}
                        />
                      )}
                      name="nameAr"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            multiline: true,
                            rows: 5,
                            onChange: handleChange("descriptionEn"),
                            value: props.value,
                          }}
                          labelText="Description (English)"
                          error={!!errors.descriptionEn?.message}
                          helperText={errors.descriptionEn?.message}
                        />
                      )}
                      name="descriptionEn"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            multiline: true,
                            rows: 5,
                            onChange: handleChange("descriptionAr"),
                            value: props.value,
                          }}
                          labelText="Description (Arabic)"
                          error={!!errors.descriptionAr?.message}
                          helperText={errors.descriptionAr?.message}
                        />
                      )}
                      name="descriptionAr"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("facebook"),
                            value: props.value,
                          }}
                          labelText="Facebook"
                          error={!!errors.facebook?.message}
                          helperText={errors.facebook?.message}
                        />
                      )}
                      name="facebook"
                      control={control}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("twitter"),
                            value: props.value,
                          }}
                          labelText="Twitter"
                          error={!!errors.twitter?.message}
                          helperText={errors.twitter?.message}
                        />
                      )}
                      name="twitter"
                      control={control}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("linkedin"),
                            value: props.value,
                          }}
                          labelText="LinkedIn"
                          error={!!errors.linkedin?.message}
                          helperText={errors.linkedin?.message}
                        />
                      )}
                      name="linkedin"
                      control={control}
                    />
                  </GridItem>
                </GridContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}
                  >
                    {`${teamMemberId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
