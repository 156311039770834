// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridList from "components/GridList/GridList";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import StudentListItem from "../../components/StudentListItem/StudentListItem";
import styles from "./studentListStyle";

const useStyles = makeStyles(styles);

export default function StudentList() {
  const classes = useStyles();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = React.useState("");

  const searchButton = classes.top + " " + classes.searchButton;

  React.useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }

        const { count, data } = await sendRequest(
          api.getStudents(page, 9, searchQuery)
        );
        setDataList(data);
        setTotalPosts(count);
      } catch (err) {}
    })();
  }, []);

  const paginate = async (pageNumber) => {
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNumber}`,
    });

    setCurrentPage(pageNumber);
    try {
      const { count, data } = await sendRequest(
        api.getStudents(pageNumber, 9, searchQuery)
      );
      setDataList(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPosts, setTotalPosts] = React.useState(1);

  const [sendRequest, isLoading] = useHttpClient();

  const [dataList, setDataList] = React.useState([]);

  const handleFetchData = async (e) => {
    e.preventDefault();
    try {
      const { count, data } = await sendRequest(
        api.getStudents(1, 9, searchQuery)
      );
      setCurrentPage(1);
      setDataList(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  return (
    <div>
      <div className={classes.topContainer}>
        <form onSubmit={handleFetchData} className={classes.searchContainer}>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classes.searchInput,
              },
              onChange: (event) => setSearchQuery(event.target.value),
              value: searchQuery,
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
            type="submit"
          >
            <Search
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
        </form>
        <Link to="/admin/students/create">
          <Button color="primary" round={true}>
            Create
          </Button>
        </Link>
      </div>

      <GridList
        data={dataList}
        loadingData={isLoading}
        CustomGridItem={StudentListItem}
        backendPagination
        currentPage={currentPage}
        paginate={paginate}
        totalPosts={totalPosts}
      />
    </div>
  );
}
