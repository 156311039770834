// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Table from "components/Table/Table.js";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./testimonialsStyle";

const useStyles = makeStyles(styles);

export default function Testimonials() {
  const classes = useStyles();

  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {name: "", title: "", comment: ""},
  });

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getTestimonials());
        setTestimonials(response);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [activeScreen, setActiveScreen] = React.useState("question");

  const [pageLoading, setPageLoading] = React.useState(true);

  const [testimonials, setTestimonials] = React.useState([]);

  const [currentTestimonial, setCurrentTestimonial] = React.useState();

  const [showTestimonialModal, setShowTestimonialModal] = React.useState(false);

  const [
    showDeleteTestimonialModal,
    setShowDeleteTestimonialModal,
  ] = React.useState(false);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => {
    setValue(prop, event.target.value);
  };

  const handleAddTestimonial = () => {
    const newIndex = testimonials.length;
    openTestimonialModal(newIndex)();
  };

  const openTestimonialModal = (index) => () => {
    setShowTestimonialModal(true);
    setCurrentTestimonial(index);
    setValue("name", testimonials[index]?.name || "");
    setValue("title", testimonials[index]?.title || "");
    setValue("comment", testimonials[index]?.comment || "");
  };

  const closeTestimonialModal = () => {
    setShowTestimonialModal(false);
    setCurrentTestimonial(undefined);
    setValue("name", "");
    setValue("title", "");
    setValue("comment", "");
    clearErrors("name");
    clearErrors("title");
    clearErrors("comment");
  };

  const openDeleteTestimonialModal = (index) => () => {
    setShowDeleteTestimonialModal(true);
    setCurrentTestimonial(index);
  };

  const closeDeleteTestimonialModal = () => {
    setShowDeleteTestimonialModal(false);
    setCurrentTestimonial(undefined);
  };

  const handleSaveTestimonial = async () => {
    try {
      const response = await sendRequest(
        api.updateTestimonial({
          ...testimonials[currentTestimonial],
          name: getValues("name"),
          title: getValues("title"),
          comment: getValues("comment"),
        }),
      );

      setTestimonials((prevTestimonials) => {
        let newTestimonials = [...prevTestimonials];
        newTestimonials[currentTestimonial] = response;
        return newTestimonials;
      });
    } catch (err) {
    } finally {
      closeTestimonialModal();
    }
  };

  const handleDeleteTestimonial = async () => {
    try {
      await sendRequest(
        api.deleteTestimonial(testimonials[currentTestimonial].id),
      );

      setTestimonials((prevTestimonials) => {
        let newTestimonials = [...prevTestimonials];
        newTestimonials.splice(currentTestimonial, 1);
        return newTestimonials;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const tableData = testimonials.map((testimonial, index) => [
    testimonial.name,
    testimonial.title,
    testimonial.comment,
    <EditIcon
      className={classes.editIcon}
      onClick={openTestimonialModal(index)}
    />,
    <DeleteIcon
      className={classes.deleteIcon}
      fontSize="small"
      onClick={openDeleteTestimonialModal(index)}
    />,
  ]);

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button color="primary" round={true} onClick={handleAddTestimonial}>
          Create
        </Button>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name", "Title", "Comment", "", ""]}
                tableData={tableData}
                coloredColls={[3]}
                colorsColls={["primary"]}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <CustomModal open={showTestimonialModal} onClose={closeTestimonialModal}>
        <form onSubmit={handleSubmit(handleSaveTestimonial)}>
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{fullWidth: true}}
                inputProps={{
                  onChange: handleChange("name"),
                  value: props.value,
                }}
                labelText="Name"
                id="name"
                error={!!errors.name?.message}
                helperText={errors.name?.message}
              />
            )}
            name="name"
            control={control}
            rules={{required: "Required"}}
          />
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{fullWidth: true}}
                inputProps={{
                  onChange: handleChange("title"),
                  value: props.value,
                }}
                labelText="Title"
                id="title"
                error={!!errors.title?.message}
                helperText={errors.title?.message}
              />
            )}
            name="title"
            control={control}
            rules={{required: "Required"}}
          />
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{fullWidth: true}}
                inputProps={{
                  onChange: handleChange("comment"),
                  value: props.value,
                }}
                labelText="Comment"
                id="comment"
                error={!!errors.comment?.message}
                helperText={errors.comment?.message}
              />
            )}
            name="comment"
            control={control}
            rules={{required: "Required"}}
          />
          <div className={classes.modalButtonContainer}>
            <Button
              color="danger"
              round={true}
              loadingColor="white"
              onClick={closeTestimonialModal}
              className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              round={true}
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.saveButton}>
              Save
            </Button>
          </div>
        </form>
      </CustomModal>

      <ConfirmModal
        isOpen={showDeleteTestimonialModal}
        onClose={() => {
          closeDeleteTestimonialModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Testimonial"
        confirmMessage={
          "Are you sure you want to delete " +
          testimonials[currentTestimonial]?.name +
          "?"
        }
        errorMessage="Something went wrong."
        successMessage="Testimonial deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteTestimonial}
        loading={isLoading && !pageLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
