// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import React from "react";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./programReviewsStyle";

const useStyles = makeStyles(styles);

export default function ProgramReviewList(props) {
  const classes = useStyles();

  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }

        const data = await sendRequest(api.getReviews(props.program.id));
        console.log(data);

        setProgramReviews(data);
      } catch (err) {}
    })();
  }, []);

  const paginate = async (pageNumber) => {
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNumber}`,
    });

    setCurrentPage(pageNumber);
    try {
      const data = await sendRequest(api.getReviews(props.program.id));

      setProgramReviews(data);
    } catch (err) {}
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [programReviews, setProgramReviews] = React.useState([]);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [current, setCurrent] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const openDeleteModal = (index) => () => {
    setShowDeleteModal(true);
    setCurrent(index);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDeleteProgramReview = async () => {
    try {
      await sendRequest(api.deleteReview(programReviews[current].id));

      setProgramReviews((prevProgramReviews) => {
        let newProgramReviews = [...prevProgramReviews];
        newProgramReviews.splice(current, 1);
        return newProgramReviews;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const tableData = programReviews.map((member, index) => [
    member.student.name,
    member.rating,
    member.comment,
    <div>
      <DeleteIcon
        className={classes.deleteIcon}
        fontSize="small"
        onClick={openDeleteModal(index)}
      />
    </div>,
  ]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Student Name", "Rating", "Comment"]}
                tableData={tableData}
                currentPage={currentPage}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ConfirmModal
        isOpen={showDeleteModal}
        onClose={() => {
          closeDeleteModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Program Review"
        confirmMessage={"Are you sure you want to delete this review?"}
        errorMessage="Something went wrong."
        successMessage="Program Review deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteProgramReview}
        loading={isLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
