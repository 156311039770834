import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

const login = (authData) => axiosInstance.post("auth/admin/login", authData);

const getAdmins = () => axiosInstance.get("admins");
const getAdmin = (id) => axiosInstance.get(`admins/${id}`);
const updateAdmin = (data) => axiosInstance.put("admins", data);
const deleteAdmin = (id) => axiosInstance.delete(`admins/${id}`);

const getNewsletter = () => axiosInstance.get("newsletter");

const getTeam = (page, limit, search) =>
  axiosInstance.get("team-members", { params: { page, limit, search } });
const getTeamMember = (id) => axiosInstance.get(`team-members/${id}`);
const updateTeamMember = (data) => axiosInstance.put("team-members", data);
const deleteTeamMember = (id) => axiosInstance.delete(`team-members/${id}`);

const getPartners = (page, limit, search) =>
  axiosInstance.get("partners", { params: { page, limit, search } });
const getPartner = (id) => axiosInstance.get(`partners/${id}`);
const updatePartner = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  return axiosInstance.put(`partners`, formData, config);
};
const deletePartner = (id) => axiosInstance.delete(`partners/${id}`);

const getTestimonials = () => axiosInstance.get("testimonials");
const updateTestimonial = (data) => axiosInstance.put("testimonials", data);
const deleteTestimonial = (id) => axiosInstance.delete(`testimonials/${id}`);

const getInstructors = (page, limit, search) =>
  axiosInstance.get("instructors", { params: { page, limit, search } });
const getInstructor = (id) => axiosInstance.get(`instructors/${id}`);
const updateInstructor = (data) => axiosInstance.put("instructors", data);
const deleteInstructor = (id) => axiosInstance.delete(`instructors/${id}`);

const getStudents = (page, limit, search) =>
  axiosInstance.get("students", { params: { page, limit, search } });
const getStudent = (id) => axiosInstance.get(`students/${id}`);
const updateStudent = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axiosInstance.put(`students`, formData, config);
};
const deleteStudent = (id) => axiosInstance.delete(`students/${id}`);

const getOrders = (page, limit, search, filter) =>
  axiosInstance.get("orders", { params: { page, limit, search, filter } });
const getOrder = (id) => axiosInstance.get(`orders/${id}`);
const updateOrder = (data) => axiosInstance.put("orders", data);
const changeOrderHasCertificate = (data) =>
  axiosInstance.put("orders/certificate", data);

const deleteProgram = (id) => axiosInstance.delete(`programs/${id}`);
const getPrograms = (page, limit, search) =>
  axiosInstance.get("programs", { params: { page, limit, search } });
const getProgram = (slug) => axiosInstance.get(`programs/${slug}`);
const updateProgram = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axiosInstance.put(`programs`, formData, config);
};

const getProgramSessions = (id) =>
  axiosInstance.get("program-sessions", { params: { id } });
const getProgramSession = (id) => axiosInstance.get(`program-sessions/${id}`);
const updateProgramSession = (data) =>
  axiosInstance.put("program-sessions", data);
const deleteProgramSession = (id) =>
  axiosInstance.delete(`program-sessions/${id}`);

const getReviews = (id) => axiosInstance.get(`reviews/${id}`);
const deleteReview = (id) => axiosInstance.delete(`reviews/${id}`);

const getRegistrationForms = (page, limit, search, id) =>
  axiosInstance.get("registration-forms", {
    params: { page, limit, search, id },
  });
const deleteRegistrationForm = (id) =>
  axiosInstance.delete(`registration-forms/${id}`);

const getMeta = (id) => axiosInstance.get(`meta`);
const updateMeta = (data) => axiosInstance.put("meta", data);

const getPromos = (page, limit, search) =>
  axiosInstance.get("promos", { params: { page, limit, search } });
const getPromo = (id) => axiosInstance.get(`promos/${id}`);
const updatePromo = (data) => axiosInstance.put("promos", data);
const deletePromo = (id) => axiosInstance.delete(`promos/${id}`);

const getAdminInfo = () => axiosInstance.get(`/admins/info?${Date.now()}_`);

export default {
  login,
  getAdmins,
  getAdmin,
  updateAdmin,
  deleteAdmin,
  getNewsletter,
  getTeam,
  getTeamMember,
  updateTeamMember,
  deleteTeamMember,
  getPartners,
  getPartner,
  updatePartner,
  deletePartner,
  getTestimonials,
  updateTestimonial,
  deleteTestimonial,
  getInstructor,
  getInstructors,
  deleteInstructor,
  updateInstructor,
  getStudents,
  getStudent,
  updateStudent,
  deleteStudent,
  getOrder,
  getOrders,
  updateOrder,
  getProgram,
  getPrograms,
  deleteProgram,
  updateProgram,
  getProgramSession,
  getProgramSessions,
  updateProgramSession,
  deleteProgramSession,
  getReviews,
  deleteReview,
  getRegistrationForms,
  deleteRegistrationForm,
  getMeta,
  updateMeta,
  getAdminInfo,
  changeOrderHasCertificate,
  getPromo,
  getPromos,
  deletePromo,
  updatePromo,
};
