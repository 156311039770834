// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import React from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./adminListStyle";

const useStyles = makeStyles(styles);

export default function AdminList() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const admins = await sendRequest(api.getAdmins());
        setAdmins(admins);
      } catch (err) {}
    })();
  }, []);

  const [admins, setAdmins] = React.useState([]);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [current, setCurrent] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const openDeleteModal = (index) => () => {
    setShowDeleteModal(true);
    setCurrent(index);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDeleteAdmin = async () => {
    try {
      await sendRequest(api.deleteAdmin(admins[current].id));

      setAdmins((prevAdmins) => {
        let newAdmins = [...prevAdmins];
        newAdmins.splice(current, 1);
        return newAdmins;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const tableData = admins.map((admin, index) => [
    admin.username,
    admin.name,
    <Link to={`/admin/admins/edit/${admin.id}`}>
      <EditIcon className={classes.editIcon} />
    </Link>,
    <DeleteIcon
      className={classes.deleteIcon}
      fontSize="small"
      onClick={openDeleteModal(index)}
    />,
  ]);

  return (
    <div>
      <div className={classes.topContainer}>
        <Link to="/admin/admins/create">
          <Button color="primary" round={true}>
            Create
          </Button>
        </Link>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Username", "Name", "", ""]}
                tableData={tableData}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmModal
        isOpen={showDeleteModal}
        onClose={() => {
          closeDeleteModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Admin"
        confirmMessage={
          "Are you sure you want to delete " + admins[current]?.name + "?"
        }
        errorMessage="Something went wrong."
        successMessage="Admin deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteAdmin}
        loading={isLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
