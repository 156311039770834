// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./instructorAssignStyle";
import strings from "constants/strings";
import SelectInput from "components/SelectInput/SelectInput";

const useStyles = makeStyles(styles);

export default function InstructorAssign() {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      gender: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getInstructor(id));
          setValue("nameEn", response.name.en);
          setValue("nameAr", response.name.ar);
          setValue("descriptionEn", response.description.en);
          setValue("descriptionAr", response.description.ar);
          setValue("gender", response.gender);
          setValue("email", response.email);
          setValue("phone", response.phone);
          setInstructorId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [instructorId, setInstructorId] = React.useState();
  const [image, setImage] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateInstructor = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;

    try {
      const response = await sendRequest(
        api.updateInstructor({
          ...(instructorId ? {id: instructorId} : {}),
          name: {en: getValues("nameEn"), ar: getValues("nameAr")},
          description: {
            en: getValues("descriptionEn"),
            ar: getValues("descriptionAr"),
          },
          gender: getValues("gender"),
          email: getValues("email"),
          phone: getValues("phone"),

        }),
      );

      if (!instructorId) {
        setInstructorId(response.id);
        history.push({
          pathname: `/admin/instructors/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateInstructor)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("nameEn"),
                            value: props.value,
                          }}
                          labelText="Name (English)"
                          error={!!errors.nameEn?.message}
                          helperText={errors.nameEn?.message}
                        />
                      )}
                      name="nameEn"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("nameAr"),
                            value: props.value,
                          }}
                          labelText="Name (Arabic)"
                          error={!!errors.nameAr?.message}
                          helperText={errors.nameAr?.message}
                        />
                      )}
                      name="nameAr"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            multiline: true,
                            rows: 5,
                            onChange: handleChange("descriptionEn"),
                            value: props.value,
                          }}
                          labelText="Description (English)"
                          error={!!errors.descriptionEn?.message}
                          helperText={errors.descriptionEn?.message}
                        />
                      )}
                      name="descriptionEn"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            multiline: true,
                            rows: 5,
                            onChange: handleChange("descriptionAr"),
                            value: props.value,
                          }}
                          labelText="Description (Arabic)"
                          error={!!errors.descriptionAr?.message}
                          helperText={errors.descriptionAr?.message}
                        />
                      )}
                      name="descriptionAr"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("email"),
                            value: props.value,
                          }}
                          labelText="Email"
                          error={!!errors.email?.message}
                          helperText={errors.email?.message}
                        />
                      )}
                      name="email"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            onChange: handleChange("phone"),
                            value: props.value,
                          }}
                          labelText="Phone"
                          error={!!errors.phone?.message}
                          helperText={errors.phone?.message}
                        />
                      )}
                      name="phone"
                      control={control}
                      rules={{required: "Required"}}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <SelectInput
                          labelText="Gender"
                          placeholder="Gender"
                          data={strings.gender.map((el, index) => ({
                            name: el,
                            value: index,
                          }))}
                          value={props.value}
                          onSelect={handleChange("gender")}
                        />
                      )}
                      name="gender"
                      control={control}
                    />
                  </GridItem>
                </GridContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}>
                    {`${instructorId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
