// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./studentAssignStyle";
import validators from "shared/util/validators";

const useStyles = makeStyles(styles);

export default function StudentAssign() {
  const confirmPasswordRules = {
    validate: {
      notEqualPassword: (value) => {
        return (
          value === getValues("password") ||
          "Password and confirm password must be the same"
        );
      },
    },
  };

  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      certificateName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getStudent(id));
          setValue("name", response.name);
          setValue("certificateName", response.certificateName);
          setValue("email", response.email);
          setValue("phone", response.phone);
          setCountryCode(response.countryCode);
          if (response.image) setImage(response.image);
          setStudentId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [studentId, setStudentId] = React.useState();
  const [image, setImage] = React.useState(null);
  const [countryCode, setCountryCode] = React.useState("");

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateStudent = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;
    console.log({file});
    try {
      const response = await sendRequest(
        api.updateStudent({
          ...(studentId ? {id: studentId} : {}),
          name: getValues("name"),
          certificateName: getValues("certificateName"),
          email: getValues("email"),
          phone: getValues("phone"),
          ...(getValues("password") !== ""
            ? {password: getValues("password")}
            : {}),
          file,
        }),
      );

      if (response.image) setImage(response.image);
      if (!studentId) {
        setStudentId(response.id);
        history.push({
          pathname: `/admin/students/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateStudent)}>
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        onChange: handleChange("name"),
                        value: props.value,
                      }}
                      labelText="Name"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                    />
                  )}
                  name="name"
                  control={control}
                  rules={{required: "Required"}}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        onChange: handleChange("certificateName"),
                        value: props.value,
                      }}
                      labelText="Certificate Name"
                      error={!!errors.certificateName?.message}
                      helperText={errors.certificateName?.message}
                    />
                  )}
                  name="certificateName"
                  control={control}
                  rules={{required: "Required"}}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        onChange: handleChange("email"),
                        value: props.value,
                      }}
                      labelText="Email"
                      error={!!errors.email?.message}
                      helperText={errors.email?.message}
                    />
                  )}
                  name="email"
                  control={control}
                  rules={validators.email}
                />
                <div>
                  <Controller
                    render={(props) => (
                      <CustomInput
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          onChange: handleChange("phone"),
                          value: props.value,
                        }}
                        labelText="phone"
                        error={!!errors.phone?.message}
                        helperText={errors.phone?.message}
                      />
                    )}
                    name="phone"
                    control={control}
                    rules={validators.phone}
                  />
                </div>
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        onChange: handleChange("password"),
                        value: props.value,
                      }}
                      labelText="Password"
                      error={!!errors.password?.message}
                      helperText={errors.password?.message}
                    />
                  )}
                  name="password"
                  control={control}
                  rules={validators.password}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        onChange: handleChange("confirmPassword"),
                        value: props.value,
                      }}
                      labelText="Confirm Password"
                      error={!!errors.confirmPassword?.message}
                      helperText={errors.confirmPassword?.message}
                    />
                  )}
                  name="confirmPassword"
                  control={control}
                  rules={confirmPasswordRules}
                />
                <ImageUpload
                  file={image}
                  setFile={setImage}
                  addButtonProps={{
                    color: "black",
                    round: true,
                  }}
                  changeButtonProps={{
                    color: "black",
                    round: true,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true,
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}>
                    {`${studentId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
