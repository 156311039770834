import enums from "enums";

export default {
  daysOfTheWeek: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  gender: ["Male", "Female"],
  hasCertificate: {
    [enums.HasCertificate.YES]: "Yes",
    [enums.HasCertificate.NO]: "No",
  },
  paymentStatus: {
    [enums.PaymentStatus.PENDING]: "Pending",
    [enums.PaymentStatus.PAID]: "Paid",
    [enums.PaymentStatus.CANCELLED]: "Cancelled",
  },
  paymentType: {
    [enums.PaymentType.BANK_TRANSFER]: "Bank transfer",
    [enums.PaymentType.ONLINE]: "Online",
  },
  programStatus: {
    [enums.ProgramStatus.OPEN]: "Open",
    [enums.ProgramStatus.UPCOMING]: "Upcoming",
    [enums.ProgramStatus.CLOSED]: "Closed",
  },
  programType: {
    [enums.ProgramType.LIVE]: "Live",
    [enums.ProgramType.RECORDED]: "Recorded",
    [enums.ProgramType.BOTH]: "Both",
  },
  programCategory: {
    [enums.ProgramCategories.LANGUAGE_TRAIN]: "Language Train",
    [enums.ProgramCategories.CHARACTER_BUILDING]: "Character Building",
    [enums.ProgramCategories.SKILLS_DEVELOPEMENT]: "Skills Development",
  },
  promoStatus: {
    [enums.PromoStatus.NOTAPPLIED]: "Not Applied",
    [enums.PromoStatus.APPLIED]: "Applied",
    [enums.PromoStatus.USED]: "Used",
  },
};
