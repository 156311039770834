// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import GridContainer from "components/Grid/GridContainer.js";
import CheckBoxDropdown from "components/CheckBoxDropdown/CheckBoxDropdown";
import GridItem from "components/Grid/GridItem.js";
import SelectInput from "components/SelectInput/SelectInput";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./programDetailsStyle";
import strings from "constants/strings";
import ImageUpload from "components/CustomUpload/ImageUpload";
import {dangerColor} from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const fields = [
  "nameEn",
  "nameAr",
  "instructorId",
  "price",
  "ageStart",
  "ageEnd",
  "type",
  "status",
  "category",
];

export default function ProgramDetails(props) {
  React.useEffect(() => {
    setInstructors(props.instructors);
    if (props?.program) {
      Object.keys(props.program).forEach((key) =>
        setValue(key, props.program[key]),
      );
      if (props.program?.description?.en) {
        setDescriptionEn(props.program.description.en);
        setDescriptionAr(props.program.description.ar);
      }
      if (props.program?.descriptionText?.en) {
        setDescriptionTextEn(props.program.descriptionText.en);
        setDescriptionTextAr(props.program.descriptionText.ar);
      }
      if (props.program?.whatYouWillLearn?.en) {
        setWhatYouWillLearnEn(props.program.whatYouWillLearn.en);
        setWhatYouWillLearnAr(props.program.whatYouWillLearn.ar);
      }
      if (props.program?.name?.ar) {
        setValue("nameAr", props.program.name.ar);
        setValue("nameEn", props.program.name.en);
      }
      if (props.program?.videos) {
        setVideos(props.program.videos);
      }
      if (props.program?.image) setImage(props.program.image);
      if (props.program?.instructors) {
        let programInstructorsIds = props.program.instructors.map(
          (instructor) => instructor.id,
        );
        setInstructorsIds(programInstructorsIds);
      }
      setProgramId(props.program.id);
      setPageLoading(false);
    }
  }, [props]);

  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      nameEn: "",
      nameAr: "",
      instructorId: "",
      price: "",
      ageStart: "",
      ageEnd: "",
      type: "",
      status: "",
      category: "",
    },
  });

  const [pageLoading, setPageLoading] = React.useState(true);

  const [programId, setProgramId] = React.useState();
  const [instructors, setInstructors] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();
  const [image, setImage] = React.useState(null);
  const [videos, setVideos] = React.useState([""]);
  const [descriptionAr, setDescriptionAr] = React.useState();
  const [descriptionEn, setDescriptionEn] = React.useState();
  const [descriptionTextAr, setDescriptionTextAr] = React.useState();
  const [descriptionTextEn, setDescriptionTextEn] = React.useState();
  const [whatYouWillLearnAr, setWhatYouWillLearnAr] = React.useState();
  const [whatYouWillLearnEn, setWhatYouWillLearnEn] = React.useState();
  const [instructorsIds, setInstructorsIds] = React.useState([]);

  const instructorIdCheckBoxHandler = (event) =>
    setInstructorsIds(event.target.value);

  const addVideoLink = () => {
    setVideos([...videos, ""]);
  };

  const handleVideoChange = (event, index) => {
    let videosLinks = [...videos];
    videosLinks[index] = event.target.value;
    setVideos(videosLinks);
  };

  const handleChange = (prop, inputType = "text") => (event) => {
    const value =
      inputType === "boolean" ? event.target.checked : event.target.value;
    setValue(prop, value);
  };

  const handleDescriptionTextAr = (editorState) => {
    setDescriptionTextAr(editorState.getCurrentContent().getPlainText());
  };

  const handleDescriptionTextEn = (editorState) => {
    setDescriptionTextEn(editorState.getCurrentContent().getPlainText());
  };

  const updateProgramDetails = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;
    let data = {};
    data.file = file;

    
    console.log(instructorsIds);
    if (instructorsIds.length > 0)
      data.instructorsIds = JSON.stringify(instructorsIds);

    if (programId) data.id = programId;
    data.description = {};
    data.descriptionText = {};
    data.whatYouWillLearn = {};
    data.name = JSON.stringify({
      en: getValues("nameEn"),
      ar: getValues("nameAr"),
    });

    if (descriptionAr) {
      data.description.ar = descriptionAr;
      data.descriptionText.ar = descriptionTextAr;
    }
    if (descriptionEn) {
      data.description.en = descriptionEn;
      data.descriptionText.en = descriptionTextEn;
    }
    if (whatYouWillLearnAr) {
      data.whatYouWillLearn.ar = whatYouWillLearnAr;
    }
    if (whatYouWillLearnEn) {
      data.whatYouWillLearn.en = whatYouWillLearnEn;
    }
    data.descriptionText = JSON.stringify(data.descriptionText);
    data.whatYouWillLearn = JSON.stringify(data.whatYouWillLearn);
    data.description = JSON.stringify(data.description);
    data.videos = JSON.stringify(videos);
    fields.forEach((field) => {
      if (getValues(field) !== "" && getValues(field) !== -1) {
        data[field] = getValues(field);
      }
    });
    try {
      console.log(data);
      const response = await sendRequest(api.updateProgram(data));
      if (response.image) setImage(response.image);

      if (!programId) {
        setProgramId(response.id);
        history.push({
          pathname: `/admin/programs/edit/${response.slug}`,
        });
      }
    } catch (err) {}
  };

  const handleDeleteVideo = (index) => {
    setVideos((prevVideos) => {
      let newVideos = [...prevVideos];
      newVideos.splice(index, 1);
      return newVideos;
    });
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit(updateProgramDetails)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: handleChange("nameEn"),
                      value: props.value,
                    }}
                    labelText="Name (English)"
                    error={!!errors.nameEn?.message}
                    helperText={errors.nameEn?.message}
                  />
                )}
                name="nameEn"
                control={control}
                rules={{required: "Required"}}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: handleChange("ageStart"),
                      value: props.value,
                    }}
                    labelText="Age Start"
                    error={!!errors.ageStart?.message}
                    helperText={errors.ageStart?.message}
                  />
                )}
                name="ageStart"
                control={control}
                rules={{required: "Required"}}
              />
              <CheckBoxDropdown
                // error={this.state.showRequiredMessage.features}
                helperText={"* Select instructors"}
                labelText="Select instructors"
                value={"-"}
                onSelect={instructorIdCheckBoxHandler}
                values={instructorsIds}
                //label="Select type"
                options={instructors.map((instructor) => ({
                  name: instructor.name.en,
                  value: instructor.id,
                }))}
              />
              <Controller
                render={(props) => (
                  <SelectInput
                    id="category"
                    labelText="Category"
                    placeholder="Category"
                    data={Object.keys(strings.programCategory).map(
                      (key, index) => ({
                        name: strings.programCategory[key],
                        value: key,
                      }),
                    )}
                    value={props.value}
                    onSelect={handleChange("category")}
                  />
                )}
                name="category"
                control={control}
                rules={{required: "Required"}}
              />
              <Controller
                render={(props) => (
                  <SelectInput
                    id="type"
                    labelText="Type"
                    placeholder="Type"
                    data={Object.keys(strings.programType).map(
                      (key, index) => ({
                        name: strings.programType[key],
                        value: key,
                      }),
                    )}
                    value={props.value}
                    onSelect={handleChange("type")}
                  />
                )}
                name="type"
                control={control}
                rules={{required: "Required"}}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: handleChange("nameAr"),
                      value: props.value,
                    }}
                    labelText="Name (Arabic)"
                    error={!!errors.nameAr?.message}
                    helperText={errors.nameAr?.message}
                  />
                )}
                name="nameAr"
                control={control}
                rules={{required: "Required"}}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: handleChange("ageEnd"),
                      value: props.value,
                    }}
                    labelText="Age End"
                    error={!!errors.ageEnd?.message}
                    helperText={errors.ageEnd?.message}
                  />
                )}
                name="ageEnd"
                control={control}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: handleChange("price"),
                      value: props.value,
                    }}
                    labelText="Price ($)"
                    error={!!errors.price?.message}
                    helperText={errors.price?.message}
                  />
                )}
                name="price"
                control={control}
              />
              <Controller
                render={(props) => (
                  <SelectInput
                    id="status"
                    labelText="Status"
                    placeholder="Status"
                    data={Object.keys(strings.programStatus).map(
                      (key, index) => ({
                        name: strings.programStatus[key],
                        value: key,
                      }),
                    )}
                    value={props.value}
                    onSelect={handleChange("status")}
                  />
                )}
                name="status"
                control={control}
                rules={{required: "Required"}}
              />
            </GridItem>
            {videos.map((video, index) => {
              return (
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    formControlProps={{className: classes.inputWidth}}
                    inputProps={{
                      onChange: (event) => handleVideoChange(event, index),
                      value: video,
                    }}
                    labelText="Video Link"
                  />
                  <DeleteIcon
                    style={{cursor: "pointer", color: dangerColor[0]}}
                    fontSize="small"
                    onClick={() => handleDeleteVideo(index)}
                  />
                </GridItem>
              );
            })}
            <div className={classes.buttonAddVideo}>
              <Button
                color="primary"
                round={true}
                className={classes.button}
                onClick={addVideoLink}>
                Add video link
              </Button>
            </div>
          </GridContainer>
          <label className={classes.contentLabel}>Description (English)</label>
          <RichTextInput
            rootStyle={{marginBottom: 50}}
            value={descriptionEn}
            setValue={setDescriptionEn}
            onChange={handleDescriptionTextEn}
          />
          <label className={classes.contentLabel}>Description (Arabic)</label>
          <RichTextInput
            dir="rtl"
            rootStyle={{marginBottom: 50}}
            value={descriptionAr}
            setValue={setDescriptionAr}
            onChange={handleDescriptionTextAr}
          />
          <label className={classes.contentLabel}>
            What you will learn (English)
          </label>
          <RichTextInput
            rootStyle={{marginBottom: 50}}
            value={whatYouWillLearnEn}
            setValue={setWhatYouWillLearnEn}
          />
          <label className={classes.contentLabel}>
            What you will learn (Arabic)
          </label>
          <RichTextInput
            dir="rtl"
            rootStyle={{marginBottom: 50}}
            value={whatYouWillLearnAr}
            setValue={setWhatYouWillLearnAr}
          />
          <ImageUpload
            file={image}
            setFile={setImage}
            addButtonProps={{
              color: "primary",
              round: true,
            }}
            changeButtonProps={{
              color: "primary",
              round: true,
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <Button
              color="primary"
              round={true}
              type="submit"
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.button}>
              {`${programId ? "Update" : "Create"}`}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
