// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import validators from "shared/util/validators";
import styles from "./adminAssignStyle";

const useStyles = makeStyles(styles);

export default function AdminAssign() {
  const classes = useStyles();

  const history = useHistory();

  const confirmPasswordRules = {
    validate: {
      notEqualPassword: (value) => {
        return (
          value === getValues("password") ||
          "Password and confirm password must be the same"
        );
      },
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      username: "",
      name: "",
      password: "",
      confirmPassword: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getAdmin(id));
          setValue("username", response.username);
          setValue("name", response.name);
          setAdminId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [adminId, setAdminId] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateAdmin = async () => {
    try {
      const response = await sendRequest(
        api.updateAdmin({
          ...(adminId ? { id: adminId } : {}),
          username: getValues("username"),
          name: getValues("name"),
          ...(getValues("password") !== ""
            ? { password: getValues("password") }
            : {}),
        })
      );

      if (!adminId) {
        setAdminId(response.id);
        history.push({
          pathname: `/admin/admins/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateAdmin)}>
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("username"),
                        value: props.value,
                      }}
                      labelText="Username"
                      error={!!errors.username?.message}
                      helperText={errors.username?.message}
                    />
                  )}
                  name="username"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("name"),
                        value: props.value,
                      }}
                      labelText="Name"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                    />
                  )}
                  name="name"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("password"),
                        value: props.value,
                      }}
                      labelText="Password"
                      error={!!errors.password?.message}
                      helperText={errors.password?.message}
                    />
                  )}
                  name="password"
                  control={control}
                  rules={validators.password}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("confirmPassword"),
                        value: props.value,
                      }}
                      labelText="Confirm Password"
                      error={!!errors.confirmPassword?.message}
                      helperText={errors.confirmPassword?.message}
                    />
                  )}
                  name="confirmPassword"
                  control={control}
                  rules={confirmPasswordRules}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}
                  >
                    {`${adminId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
