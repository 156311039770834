// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import RichTextInput from "components/CustomInput/RichTextInput";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function Meta() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getMeta());
        console.log(response);
        setMeta({
          id: response?.id,
          privacyPolicyEn: response?.privacyPolicy?.en,
          privacyPolicyAr: response?.privacyPolicy?.ar,
          termsAndConditionsEn: response?.termsAndConditions?.en,
          termsAndConditionsAr: response?.termsAndConditions?.ar,
        });
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [sendRequest, isLoading] = useHttpClient();

  const [meta, setMeta] = React.useState({});

  const handleChangeMeta = (key) => (value) => {
    setMeta((prevMeta) => ({...prevMeta, [key]: value}));
  };

  const handleUpdateMeta = async () => {
    try {
      console.log(meta);
      const response = await sendRequest(
        api.updateMeta({
          ...(meta.id ? {id: meta.id} : {}),
          privacyPolicy: {en: meta.privacyPolicyEn, ar: meta.privacyPolicyAr},
          termsAndConditions: {
            en: meta.termsAndConditionsEn,
            ar: meta.termsAndConditionsAr,
          },
        }),
      );
      setMeta({
        id: response?.id,
        privacyPolicyEn: response?.privacyPolicy?.en,
        privacyPolicyAr: response?.privacyPolicy?.ar,
        termsAndConditionsEn: response?.termsAndConditions?.en,
        termsAndConditionsAr: response?.termsAndConditions?.ar,
      });
    } catch (err) {}
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <Button
          color="primary"
          round={true}
          loading={isLoading && !pageLoading}
          loadingColor="white"
          onClick={handleUpdateMeta}>
          Publish
        </Button>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{textAlign: "center", fontWeight: "bold"}}>
                Privacy Policy (English)
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{marginBottom: 20}}
                value={meta?.privacyPolicyEn}
                setValue={handleChangeMeta("privacyPolicyEn")}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{textAlign: "center", fontWeight: "bold"}}>
                Privacy Policy (Arabic)
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                dir="rtl"
                rootStyle={{marginBottom: 20}}
                value={meta?.privacyPolicyAr}
                setValue={handleChangeMeta("privacyPolicyAr")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{textAlign: "center", fontWeight: "bold"}}>
                Terms And Conditions (English)
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{marginBottom: 20}}
                value={meta?.termsAndConditionsEn}
                setValue={handleChangeMeta("termsAndConditionsEn")}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{textAlign: "center", fontWeight: "bold"}}>
                Terms And Conditions (Arabic)
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                dir="rtl"
                rootStyle={{marginBottom: 20}}
                value={meta?.termsAndConditionsAr}
                setValue={handleChangeMeta("termsAndConditionsAr")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
