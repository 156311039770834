// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./orderListStyle";
import strings from "constants/strings";
import enums from "enums";
import { getDate } from "utils";
import CheckBoxDropdown from "components/CheckBoxDropdown/CheckBoxDropdown";

const useStyles = makeStyles(styles);

export default function OrderList() {
  const classes = useStyles();

  const history = useHistory();

  const searchButton = classes.top + " " + classes.searchButton;

  const [paymentStatus, setPaymentStatus] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }

        const { count, data } = await sendRequest(
          api.getOrders(page, 10, searchQuery, paymentStatus)
        );

        setOrders(data);
        setTotalPosts(count);

        console.log(data);
        console.log(count);
      } catch (err) {}
    })();
  }, []);

  const paymentStatusCheckBoxHandler = async (event) => {
    setPaymentStatus(event.target.value);
    console.log(event.target.value);
    try {
      const { count, data } = await sendRequest(
        api.getOrders(1, 10, searchQuery, event.target.value)
      );
      setCurrentPage(1);
      setOrders(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const paginate = async (pageNumber) => {
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNumber}`,
    });

    setCurrentPage(pageNumber);
    try {
      const { count, data } = await sendRequest(
        api.getOrders(pageNumber, 10, searchQuery, paymentStatus)
      );
      setOrders(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPosts, setTotalPosts] = React.useState(1);
  const [orders, setOrders] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [current, setCurrent] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const handleFetchData = async (e) => {
    e.preventDefault();
    try {
      console.log(searchQuery);
      const { count, data } = await sendRequest(
        api.getOrders(1, 10, searchQuery, paymentStatus)
      );
      setCurrentPage(1);
      setOrders(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const openDeleteModal = (index) => () => {
    setShowDeleteModal(true);
    setCurrent(index);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDeleteOrder = async () => {
    try {
      setOrders((prevOrders) => {
        let newOrders = [...prevOrders];
        newOrders.splice(current, 1);
        return newOrders;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const tableData = orders.map((member, index) => [
    member.orderToken,
    member.studentName,
    member.programName,
    member.price,
    member.promoDiscount ? member.promoDiscount * 100 + "%" : "-",
    strings.paymentStatus[member.status],
    strings.paymentType[member.type],
    member.paymentRefId,
    getDate(member.createdAt),
    <Link to={`/admin/orders/edit/${member.id}`}>
      <EditIcon className={classes.editIcon} />
    </Link>,
  ]);

  return (
    <div>
      <div className={classes.topContainer}>
        <form onSubmit={handleFetchData} className={classes.searchContainer}>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classes.searchInput,
              },
              onChange: (event) => setSearchQuery(event.target.value),
              value: searchQuery,
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
            type="submit"
          >
            <Search
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
          <div style={{ marginLeft: "20px", width: "15%" }}>
            <CheckBoxDropdown
              // error={this.state.showRequiredMessage.features}
              // helperText={"* Select instructors"}
              labelText="Payment Status"
              value={"-"}
              onSelect={paymentStatusCheckBoxHandler}
              values={paymentStatus}
              //label="Select type"
              options={
                //   instructors.map((instructor) => ({
                //   name: instructor.name.en,
                //   value: instructor.id,
                // }))
                [
                  {
                    name: strings.paymentStatus[enums.PaymentStatus.PENDING],
                    value: enums.PaymentStatus.PENDING,
                  },
                  {
                    name: strings.paymentStatus[enums.PaymentStatus.PAID],
                    value: enums.PaymentStatus.PAID,
                  },
                  {
                    name: strings.paymentStatus[enums.PaymentStatus.CANCELLED],
                    value: enums.PaymentStatus.CANCELLED,
                  },
                ]
              }
            />
          </div>
        </form>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "ID",
                  "Student Name",
                  "Program Name",
                  "Price",
                  "Discount(%)",
                  "Status",
                  "Type",
                  "Payment Ref Id",
                  "Created At",
                  "Actions",
                ]}
                tableData={tableData}
                backendPagination
                currentPage={currentPage}
                paginate={paginate}
                totalPosts={totalPosts}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ConfirmModal
        isOpen={showDeleteModal}
        onClose={() => {
          closeDeleteModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Order"
        confirmMessage={
          "Are you sure you want to delete " + orders[current]?.name.en + "?"
        }
        errorMessage="Something went wrong."
        successMessage="Order deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteOrder}
        loading={isLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
