// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./orderAssignStyle";
import strings from "constants/strings";
import SelectInput from "components/SelectInput/SelectInput";

const useStyles = makeStyles(styles);

export default function OrderAssign() {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      status: "",
    },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getOrder(id));
          setValue("status", response.status);
          setOrderId(response.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [orderId, setOrderId] = React.useState();
  const [image, setImage] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updateOrder = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;

    try {
      const response = await sendRequest(
        api.updateOrder({
          ...(orderId ? {id: orderId} : {}),
          status: getValues("status"),
        }),
      );

      if (!orderId) {
        setOrderId(response.id);
        history.push({
          pathname: `/admin/orders/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateOrder)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <SelectInput
                          labelText="Payment Status"
                          placeholder="Status"
                          data={Object.keys(strings.paymentStatus).map(
                            (key, index) => ({
                              name: strings.paymentStatus[key],
                              value: key,
                            }),
                          )}
                          value={props.value}
                          onSelect={handleChange("status")}
                        />
                      )}
                      name="status"
                      control={control}
                    />
                  </GridItem>
                </GridContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}>
                    {`${orderId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
