import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "./checkBoxDropdownStyle";

const useStyles = makeStyles(styles);

export default function CheckBoxDropdown(props) {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const classes = useStyles();

  let menuItems = props.options.map((option, index) => (
    <MenuItem key={index} className={classes.menuItem} value={option.value}>
      <Checkbox checked={props.values.includes(option.value)} color="primary" />
      {option.name}
    </MenuItem>
  ));
  let selectProps = {};
  if (props.value != "-") {
    selectProps.value = props.value;
  }

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>

      {props.labelText !== undefined ? (
        <InputLabel
          // className={classes.labelRoot + " " + labelClasses}
          className={classes.selectLabel}
          htmlFor={`simple-select-${props.id}`}
          // {...labelProps}
        >
          {props.labelText}
        </InputLabel>
      ) : null}
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        multiple
        value={props.values}
        onChange={props.onSelect}
        labelWidth={labelWidth}
        renderValue={(selected) => {
          let seletctedValues = props.options.map((option, index) => {
            if (selected.includes(option.value)) {
              return option.name;
            }
          });
          let filteredValues = seletctedValues.filter((value) => value);
          return filteredValues.join(", ");
        }}
      >
        {props.defaultValue ? (
          <MenuItem disabled className={classes.menuItem} value={undefined}>
            <em>{props.defaultValue}</em>
          </MenuItem>
        ) : null}
        {menuItems}
      </Select>
      <FormHelperText>{props.error ? props.helperText : false}</FormHelperText>
    </FormControl>
  );
}
