// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import React from "react";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import api from "services/api";
import {useHttpClient} from "shared/hooks/http-hook";
import styles from "./instructorListStyle";

const useStyles = makeStyles(styles);

export default function InstructorList() {
  const classes = useStyles();

  const history = useHistory();

  const searchButton = classes.top + " " + classes.searchButton;

  React.useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }

        const {count, data} = await sendRequest(
          api.getInstructors(page, 10, searchQuery),
        );

        setInstructors(data);
        setTotalPosts(count);
      } catch (err) {}
    })();
  }, []);

  const paginate = async (pageNumber) => {
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNumber}`,
    });

    setCurrentPage(pageNumber);
    try {
      const {count, data} = await sendRequest(
        api.getInstructors(pageNumber, 10, searchQuery),
      );

      setInstructors(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPosts, setTotalPosts] = React.useState(1);
  const [instructors, setInstructors] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [current, setCurrent] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const handleFetchData = async (e) => {
    e.preventDefault();
    try {
      const {count, data} = await sendRequest(
        api.getInstructors(1, 10, searchQuery),
      );
      setCurrentPage(1);
      setInstructors(data);
      setTotalPosts(count);
    } catch (err) {}
  };

  const openDeleteModal = (index) => () => {
    setShowDeleteModal(true);
    setCurrent(index);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDeleteInstructor = async () => {
    try {
      await sendRequest(api.deleteInstructor(instructors[current].id));

      setInstructors((prevInstructors) => {
        let newInstructors = [...prevInstructors];
        newInstructors.splice(current, 1);
        return newInstructors;
      });
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const tableData = instructors.map((member, index) => [
    member.name.en,
    member.gender ? "Female" : "Male",
    member.email,
    member.phone,
    <div>
      <Link to={`/admin/instructors/edit/${member.id}`}>
        <EditIcon className={classes.editIcon} />
      </Link>
      <DeleteIcon
        className={classes.deleteIcon}
        fontSize="small"
        onClick={openDeleteModal(index)}
      />
    </div>,
  ]);

  return (
    <div>
      <div className={classes.topContainer}>
        <form onSubmit={handleFetchData} className={classes.searchContainer}>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classes.searchInput,
              },
              onChange: (event) => setSearchQuery(event.target.value),
              value: searchQuery,
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
            type="submit">
            <Search
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
        </form>
        <Link to="/admin/instructors/create">
          <Button color="primary" round={true}>
            Create
          </Button>
        </Link>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name", "Gender", "Email", "Phone", "Actions"]}
                tableData={tableData}
                backendPagination
                currentPage={currentPage}
                paginate={paginate}
                totalPosts={totalPosts}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ConfirmModal
        isOpen={showDeleteModal}
        onClose={() => {
          closeDeleteModal();
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Instructor"
        confirmMessage={
          "Are you sure you want to delete " +
          instructors[current]?.name.en +
          "?"
        }
        errorMessage="Something went wrong."
        successMessage="Instructor deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={handleDeleteInstructor}
        loading={isLoading}
        activeScreen={activeScreen}
      />
    </div>
  );
}
