// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SessionsIcon from "@material-ui/icons/Class";
import InfoIcon from "@material-ui/icons/Info";
import RegistrationIcon from "@material-ui/icons/Assignment";
import ReviewsIcon from "@material-ui/icons/RateReview";
import CustomTabs from "components/CustomTabs/CustomTabs";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import ProgramDetails from "../../components/ProgramDetails/ProgramDetails";
import ProgramReviews from "../../components/ProgramReviews/ProgramReviews";
import ProgramRegistrationForms from "../../components/ProgramRegistrationForms/ProgramRegistrationForms";
import ProgramSessions from "../../components/ProgramSessions/pages/ProgramSessionList/ProgramSessionList";
import styles from "./programAssignStyle";
import { useLocation } from "react-router";

const useStyles = makeStyles(styles);

export default function ProgramAssign() {
  const classes = useStyles();

  const [id, setId] = React.useState(
    window.location.pathname.split("/").pop().trim()
  );

  const [program, setProgram] = React.useState();
  const [instructors, setInstructors] = React.useState([]);

  const [sendRequest] = useHttpClient();
  const location = useLocation();
  React.useEffect(() => {
    (async () => {
      try {
        const instructors = await sendRequest(api.getInstructors());
        setInstructors(instructors.data);
        if (id !== "create") {
          const program = await sendRequest(api.getProgram(id));
          setProgram(program);
        } else if (location.state?.slug) {
          const program = await sendRequest(
            api.getProgram(location.state.slug)
          );
          setProgram({
            name: program.name,
            videos: program.videos,
            price: program.price,
            ageStart: program.ageStart,
            ageEnd: program.ageEnd,
            description: program.description,
            descriptionText: program.descriptionText,
            whatYouWillLearn: program.whatYouWillLearn,
            type: program.type,
            status: program.status,
            category: program.category,
            instructors: program.instructors,
          });
        }
      } catch (err) {}
    })();
  }, []);

  let tabs = [
    {
      tabName: "Details",
      tabIcon: InfoIcon,
      tabContent: (
        <ProgramDetails program={program} instructors={instructors} />
      ),
    },
  ];

  if (id !== "create") {
    tabs.push(
      {
        tabName: "Sessions",
        tabIcon: SessionsIcon,
        tabContent: <ProgramSessions program={program} />,
      },
      {
        tabName: "Reviews",
        tabIcon: ReviewsIcon,
        tabContent: <ProgramReviews program={program} />,
      },
      {
        tabName: "Registration Forms",
        tabIcon: RegistrationIcon,
        tabContent: <ProgramRegistrationForms program={program} />,
      }
    );
  }

  return (
    <div>
      <CustomTabs
        headerColor="primary"
        rootClasses={classes.customTabs}
        tabs={tabs}
      />
    </div>
  );
}
