import { useState, useCallback, useEffect } from "react";
import api from "services/api";

let logoutTimer;

export const useAuth = () => {
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userData, setUserData] = useState(false);

  const login = useCallback((userData, expirationDate) => {
    const tokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);
    setTokenExpirationDate(tokenExpirationDate);
    setUserData(userData);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...userData,
        expirationDate: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUserData(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (userData && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [userData, logout, tokenExpirationDate]);

  useEffect(() => {
    const authAutoSignIn = async () => {
      try {
        const user = (await api.getAdminInfo()).data;
        login(user);
      } catch (err) {
        setUserData(null);
        localStorage.removeItem("userData");
      }
    };
    authAutoSignIn();
  }, [login]);

  return { userData, login, logout };
};
