// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./promoAssignStyle";
import strings from "constants/strings";
import SelectInput from "components/SelectInput/SelectInput";

const useStyles = makeStyles(styles);

export default function PromoAssign() {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      code: "",
      discount: "",
      status: "",
      studentId: "",
      programId: "",
    },
  });

  const compareObjects = (object1, object2, key) => {
    const obj1 = object1[key].toUpperCase();
    const obj2 = object2[key].toUpperCase();

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  };

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      const allStudents = await sendRequest(api.getStudents());
      let filteredStudents = allStudents.data.filter((student) => {
        if (student.emailVerified) {
          return { name: student.name, id: student.id };
        }
      });
      filteredStudents.sort((student1, student2) => {
        return compareObjects(student1, student2, "name");
      });
      setStudents(filteredStudents);

      const allPrograms = await sendRequest(api.getPrograms());
      let filteredPrograms = allPrograms.data.map((program) => {
        return { name: program.name.en, id: program.id };
      });
      filteredPrograms.sort((program1, program2) => {
        return compareObjects(program1, program2, "name");
      });
      setPrograms(filteredPrograms);
      if (id !== "create") {
        try {
          const promoInfo = await sendRequest(api.getPromo(id));

          setValue("code", promoInfo.code);
          setValue("discount", promoInfo.discount * 100);
          setValue("status", promoInfo.status);
          setValue("studentId", promoInfo.studentId);
          setValue("programId", promoInfo.programId);
          setPromoId(promoInfo.id);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [promoId, setPromoId] = React.useState();
  const [students, setStudents] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const updatePromo = async () => {
    try {
      const response = await sendRequest(
        api.updatePromo({
          ...(promoId ? { id: promoId } : {}),
          code: getValues("code"),
          discount: getValues("discount") / 100,
          status: getValues("status"),
          studentId: getValues("studentId"),
          programId: getValues("programId"),
        })
      );

      if (!promoId) {
        setPromoId(response.id);
        history.push({
          pathname: `/admin/promos/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updatePromo)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <SelectInput
                          id="studentId"
                          labelText="Student"
                          placeholder="Student"
                          data={students.map((student) => ({
                            name: student.name,
                            value: student.id,
                          }))}
                          value={props.value}
                          onSelect={handleChange("studentId")}
                        />
                      )}
                      name="studentId"
                      control={control}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <SelectInput
                          id="programId"
                          labelText="Program"
                          placeholder="Program"
                          data={programs.map((program) => ({
                            name: program.name,
                            value: program.id,
                          }))}
                          value={props.value}
                          onSelect={handleChange("programId")}
                        />
                      )}
                      name="programId"
                      control={control}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("code"),
                            value: props.value,
                          }}
                          labelText="Code"
                          error={!!errors.code?.message}
                          helperText={errors.code?.message}
                        />
                      )}
                      name="code"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Controller
                      render={(props) => (
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: handleChange("discount"),
                            value: props.value,
                          }}
                          labelText="Discount(%)"
                          error={!!errors.discount?.message}
                          helperText={errors.discount?.message}
                        />
                      )}
                      name="discount"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Controller
                      render={(props) => (
                        <SelectInput
                          id="status"
                          labelText="Status"
                          placeholder="Status"
                          data={Object.keys(strings.promoStatus).map(
                            (key, index) => ({
                              name: strings.promoStatus[key],
                              value: key,
                            })
                          )}
                          value={props.value}
                          onSelect={handleChange("status")}
                        />
                      )}
                      name="status"
                      control={control}
                      rules={{ required: "Required" }}
                    />
                  </GridItem>
                </GridContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}
                  >
                    {`${promoId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
